import React from "react";
import { Title, Image } from "../../components";
import jessicaphoto from "../../assets/professionalpicture.png";
import lessonphoto from "../../assets/pexels-cottonbro-studio-7095506.jpg";

import "./introduction.css";

const Introduction = () => {
  return (
    <>
      <div className="section-padding" />
      <Title id="about_me" text="About Me" />

      <div className="columns-1 lg:grid lg:grid-cols-4 lg:justify-start lg:gap-4">
        <div className="flex flex-col justify-between items-center lg:justify-start lg:items-start">
          <Image img={jessicaphoto} alt="Jessica Photo" className="max-h-80 lg:h-fit" />
        </div>

        <div className="section-padding lg:hidden" />
        <div className="col-span-3">
          <p>
            My name is Jessica Westerham and it is nice to meet you! I have been
            a private music instructor based in Phoenix, AZ since 2016 and want
            to give my students the opportunity to learn the joy of violin and
            piano.
            <br />
            <br />
            With nearly two decades of dedicated practice and performance on
            both the piano and violin, my passion for music has become the very
            essence of my being. My journey began at the age of 5, and since
            then, I have honed my skills through countless hours of practice,
            teaching, and participation in prestigious symphonies, including the
            renowned Phoenix Youth Symphony and Arizona All-State Symphonies.
            This invaluable experience has not only deepened my understanding of
            these two instruments but has also instilled in me the art of
            connecting with music on an emotional level and translating those
            sentiments into unforgettable performances.
            <br />
            <br />
            As a teacher, my commitment goes far beyond the mere transmission of
            technical knowledge. I take pride in nurturing the musical souls of
            my students, instilling a profound appreciation for music that will
            accompany them throughout their lives. My approach is anchored in
            the belief that everyone possesses inherent musicality; my role is
            simply to unlock that hidden talent and channel it into a harmonious
            symphony of expression. Witnessing the growth and progress of my
            students is such a huge reward, and it is this profound satisfaction
            that fuels my dedication to my students' learning.
            <br />
            <br />
            My teaching style is characterized by its adaptability, tailored to
            cater to each student's unique learning pace, preferences, and
            aspirations. Whether you are a budding prodigy or simply seeking a
            therapeutic musical outlet, I am committed to providing personalized
            guidance that nurtures your talents and ignites your passion for
            music. Through a diverse repertoire, I strive to create a dynamic
            and enjoyable learning environment that fuels creativity and
            encourages exploration.
            <br />
            <br />
            Let us create an extraordinary musical experience that will resonate
            with you for a lifetime. Whether you're a beginner taking your first
            steps into the world of music or a seasoned musician looking to
            refine your craft, I am here to inspire, guide, and empower you to
            become the best musician you can be. Let's make beautiful music
            together!
          </p>
        </div>
      </div>

      <div className="section-padding" />
      <Title id="lessons" text="Lessons" />
      
      <div className="columns-1 lg:grid lg:grid-cols-4 lg:justify-start lg:gap-4">
        <div className="flex flex-col justify-between items-center lg:justify-start lg:items-start">
          <div className="flex flex-col justify-between items-center lg:justify-start lg:items-start">
            <Image img={lessonphoto} alt="Lesson Photo" className="max-h-80 lg:h-fit lg:pr-2" />
          </div>
        </div>

        <div className="section-padding lg:hidden" />
        <div className="col-span-3">
          <p>
            Please refer to the FAQ page for details regarding preparation for your first lesson.
            <br />
            <br />
            I believe it is essential to maintain a consistent weekly schedule
            for our private lessons. Recently, there have been instances of
            last-minute cancellations without prior notice, which can disrupt
            the flow of our learning process and affect the quality of our
            lessons. I kindly request that we prioritize effective communication
            regarding lesson cancellations or potential time conflicts.
            <br />
            <br />
            My studio operates on an understanding that any lesson cancellations
            or requests for makeup lessons will be communicated to me at least
            24 hours in advance of the scheduled lesson time. This will allow me
            to plan my schedule accordingly and ensure that I can be fully
            prepared and available for each lesson. I understand that unforeseen
            circumstances may arise, and I am more than happy to accommodate
            makeup lessons as long as I am informed in a timely manner. By being
            respectful of each other's time, we can create a harmonious learning
            environment that fosters growth and progress in our musical
            endeavors.
            <br />
            <br />
            Thank you for your understanding and cooperation. I am excited to be
            a part of our musical exploration and look forward to creating
            beautiful music with each of you.
          </p>
        </div>
      </div>

      <div className="section-padding" />
      <Title id="benefits" text="Benefits of Music" />

      <div className="h-screen-50 p-1">
        <iframe
          src="https://www.youtube.com/embed/R0JKCYZ8hng"
          title="YouTube video player"
          width="100%"
          height="100%"
          allow="fullscreen;accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        />
      </div>

      <div className="p-12"></div>
    </>
  );
};

export default Introduction;
