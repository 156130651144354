import React from "react";

import "./error_page.css";

const ErrorPage = () => {

  return (
    <>
        <div className="absolute top-0 left-0 bottom-0 right-0 h-full w-full grid place-items-center">
          <div className="px-8">
            <p className=" -ml-1 text-7xl">
              Oops!
            </p>
            <p className="text-4xl">
              Looks like something went wrong.
            </p>
          </div>
        </div>
    </>
  );
};

export default ErrorPage;
