import React, { useState } from "react";
import { Accordion } from 'flowbite-react';
import { Title, AmazonProductCard } from "../../components";
import { AmazonProductCardProps } from "../../components/amazon_product_card/amazon_product_card";

import "./resources.css";

const Resources = () => {
  const violinProducts: AmazonProductCardProps[] = [
    {
      name: "Korg TM60BK Tuner Metronome",
      fullLink: "https://www.amazon.com/Korg-TM60BK-Tuner-Metronome-Black/dp/B078C5HCVP?DFCF4CD1-D9E7-154C-8D64-C9DC3053EE58_pure_cup_C6FA3ED5_6D17_47D1_B6E2_F4B02CC905E0_=&linkCode=ll1&tag=mwesterham-20&linkId=25d07e8c2e39ec442edc8350a27546c4&language=en_US&ref_=as_li_ss_tl",
    },
    {
      name: "Kun Collapsible 4/4 Violin Shoulder Rest",
      fullLink: "https://www.amazon.com/830350-Collapsible-Violin-Shoulder-Rest/dp/B0002E1F9O?7222BAB5-D60C-4A49-BAFA-BDD900685B59_pure_cup_C6FA3ED5_6D17_47D1_B6E2_F4B02CC905E0_=undefined&th=1&linkCode=ll1&tag=mwesterham-20&linkId=88e04196a200517bf4fef060f13cce6f&language=en_US&ref_=as_li_ss_tl",
    },
    {
      name: "Manhasset Model #48 Sheet Music Stand",
      fullLink: "https://www.amazon.com/Manhasset-Model-Sheet-Music-Stand/dp/B0002FOBJY?F39190B1-8042-3D44-B900-A3E1B6496E02_pure_cup_C6FA3ED5_6D17_47D1_B6E2_F4B02CC905E0_=undefined&th=1&linkCode=ll1&tag=mwesterham-20&linkId=f1e39e276cd9a2a7c48ea33ed418f2fc&language=en_US&ref_=as_li_ss_tl",
    },
    {
      name: "Jade L'Opera JADE Rosin for Violin, Viola, and Cello",
      fullLink: "https://www.amazon.com/Jade-LOpera-Rosin-Violin-Viola/dp/B002Q0WT6U?5AA7CE6D-7AFE-694D-B43B-8839DEE23EFC_pure_cup_C6FA3ED5_6D17_47D1_B6E2_F4B02CC905E0_=undefined&th=1&linkCode=ll1&tag=mwesterham-20&linkId=d39ae36f25ecf501e0eeb3e18beb0e71&language=en_US&ref_=as_li_ss_tl",
    },
  ];

  const beginnerViolin: AmazonProductCardProps[] = [
    {
      name: "78VN - All for Strings - Book 1 - Violin",
      fullLink: "https://www.amazon.com/78VN-All-Strings-Book-Violin/dp/0849732220?4118A24C-5EFC-3D40-BDD1-53E6D6D54688_pure_cup_C6FA3ED5_6D17_47D1_B6E2_F4B02CC905E0_=&linkCode=ll1&tag=mwesterham-20&linkId=b3cec757c71b785651d2eab6c6f1d413&language=en_US&ref_=as_li_ss_tl",
    },
    {
      name: "Suzuki Violin School, Vol 1: Violin Part",
      fullLink: "https://www.amazon.com/Suzuki-Violin-School-Part-Vol/dp/0757900615?1265F9E2-A988-554C-ABFC-3B41C1050BC5_pure_cup_C6FA3ED5_6D17_47D1_B6E2_F4B02CC905E0_=&linkCode=ll1&tag=mwesterham-20&linkId=29646f0e76491b7da9549c504d6055ca&language=en_US&ref_=as_li_ss_tl",
    },
  ];

  const intermediateViolin: AmazonProductCardProps[] = [
    {
      name: "Suzuki Violin School, Vol 2: Violin Part",
      fullLink: "https://www.amazon.com/Suzuki-Violin-School-Vol-Part/dp/0739048120?hvadid=316635965641&hvpos=&hvnetw=g&hvrand=16165678174670541187&hvpone=&hvptwo=&hvqmt=&hvdev=m&hvdvcmdl=&hvlocint=&hvlocphy=9030091&hvtargid=pla-473981903474&psc=1&adgrpid=58538666850&hvpone=&hvptwo=&hvadid=316635965641&hvpos=&hvnetw=g&hvrand=16165678174670541187&hvqmt=&hvdev=m&hvdvcmdl=&hvlocint=&hvlocphy=9030091&hvtargid=pla-473981903474&linkCode=ll1&tag=mwesterham-20&linkId=bd3d19a8e3e680549eae6ce0143f9485&language=en_US&ref_=as_li_ss_tl",
    },
    {
      name: "Suzuki Violin School, Vol 3: Violin Part",
      fullLink: "https://www.amazon.com/dp/0739048155?38275E40-CC69-6C44-B95B-D9E4BAD40D71_pure_cup_C6FA3ED5_6D17_47D1_B6E2_F4B02CC905E0_=&linkCode=ll1&tag=mwesterham-20&linkId=7d15ab8c30cd2e979dd1ad5c8690e383&language=en_US&ref_=as_li_ss_tl",
    },
    {
      name: "Suzuki Violin School, Vol 4: Violin Part",
      fullLink: "https://www.amazon.com/Suzuki-Violin-School-Vol-Part/dp/0739054627?crid=2R53ZZVECMU13&keywords=suzuki+violin+4&qid=1690508514&sprefix=suzuki+violin+4%2Caps%2C201&sr=8-1&linkCode=ll1&tag=mwesterham-20&linkId=2e8e6317c1b3e1046bd412ce07251f92&language=en_US&ref_=as_li_ss_tl",
    },
    {
      name: "Positions for Violin: Volume 1 - Third and Fifth",
      fullLink: "https://www.amazon.com/Introducing-Positions-Violin-Third-Position/dp/1423444876?crid=23Q2W0FXLL65R&keywords=violin+shifting+book&qid=1690508544&sprefix=violin+shifting%2Caps%2C185&sr=8-20&linkCode=ll1&tag=mwesterham-20&linkId=8ab9bb34ec4a9c106d887f19764b76dc&language=en_US&ref_=as_li_ss_tl",
    },
    {
      name: "Scales Plus!: for Violin",
      fullLink: "https://www.amazon.com/Scales-Plus-Violin-William-Starr/dp/0757924492?crid=3RHRGRQYBQBJR&keywords=scales+violin&qid=1690508813&sprefix=scales+violin%2Caps%2C167&sr=8-6&linkCode=ll1&tag=mwesterham-20&linkId=d3ff8cf748b0d5dca47af1421e13bed0&language=en_US&ref_=as_li_ss_tl",
    },
  ];

  const pianoProducts: AmazonProductCardProps[] = [
    {
      name: "Seiko SQ50-V Quartz Metronome",
      fullLink: "https://www.amazon.com/SEIKO-SQ50V-SQ50-V-Quartz-Metronome/dp/B000LFCXL8?crid=20Y0R9IFJIM7X&keywords=metronome%2Bquartz&qid=1690260448&s=books&sprefix=metronome%2Bquartz%2Cstripbooks%2C121&sr=1-1-catcorr&th=1&linkCode=ll1&tag=mwesterham-20&linkId=ad85c3f50390f90df5c7576fe590f925&language=en_US&ref_=as_li_ss_tl",
    },
  ];

  const beginnerPiano: AmazonProductCardProps[] = [
    {
      name: "Piano Adventures - Lesson Book - Primer Level",
      fullLink:
        "https://www.amazon.com/Primer-Level-Lesson-Piano-Adventures/dp/1616770759?hvadid=312115090752&hvpos=&hvnetw=g&hvrand=1267356253501648414&hvpone=&hvptwo=&hvqmt=&hvdev=m&hvdvcmdl=&hvlocint=&hvlocphy=9030091&hvtargid=pla-384435577119&psc=1&linkCode=ll1&tag=mwesterham-20&linkId=f473534b34410908cf894b439f78a8ce&language=en_US&ref_=as_li_ss_tl",
    },
    {
      name: "Piano Adventures - Lesson Book - Level 1",
      fullLink: "https://www.amazon.com/Level-Lesson-Book-Piano-Adventures/dp/1616770783?3DA49BE0-25E8-4540-897E-D71BF64865CE_pure_cup_C6FA3ED5_6D17_47D1_B6E2_F4B02CC905E0_=&linkCode=ll1&tag=mwesterham-20&linkId=495fd5e4056a582fddc2a1834665a8dc&language=en_US&ref_=as_li_ss_tl",
    },
  ];

  const intermediatePiano: AmazonProductCardProps[] = [
    {
      name: "Alfred's Basic Piano Library Lesson Book, Bk 2",
      fullLink: "https://www.amazon.com/dp/0882848127?68AD3F8E-A629-7745-814C-4F8434C33207_pure_cup_C6FA3ED5_6D17_47D1_B6E2_F4B02CC905E0_=&linkCode=ll1&tag=mwesterham-20&linkId=1a7bb4b83063f63fc2c2cbbdb7bef664&language=en_US&ref_=as_li_ss_tl",
    },
    {
      name: "Alfred's Basic Piano Course: Lesson Book - Level 3",
      fullLink: "https://www.amazon.com/Alfreds-Basic-Piano-Course-Lesson/dp/0882848151?crid=1EUZAYEE72IP2&keywords=alfred%27s+basic+piano+level+3&qid=1690508984&sprefix=alfreds+basic+piano+level+3%2Caps%2C169&sr=8-1&linkCode=ll1&tag=mwesterham-20&linkId=65959c80d5ac3efe6e669059d747d60d&language=en_US&ref_=as_li_ss_tl",
    },
    {
      name: "Piano Adventures - Lesson Book - Level 2A",
      fullLink: "https://www.amazon.com/Level-2A-Lesson-Piano-Adventures/dp/1616770813?hvadid=312166025508&hvpos=&hvnetw=g&hvrand=15506617363204915205&hvpone=&hvptwo=&hvqmt=&hvdev=m&hvdvcmdl=&hvlocint=&hvlocphy=9030091&hvtargid=pla-433851677227&psc=1&linkCode=ll1&tag=mwesterham-20&linkId=e978a6bbdeb48cf12b70a45adbb72a3f&language=en_US&ref_=as_li_ss_tl",
    },
    {
      name: "Piano Adventures - Lesson Book - Level 2B",
      fullLink: "https://www.amazon.com/Level-2B-Lesson-Piano-Adventures/dp/1616770848?crid=396N9Y35QUP2C&keywords=piano+adventures+2b&qid=1690509029&sprefix=piano+adventures+%2Caps%2C169&sr=8-1&linkCode=ll1&tag=mwesterham-20&linkId=5bf918ee9734d16533fc300988df65b8&language=en_US&ref_=as_li_ss_tl",
    },
    {
      name: "Hanon -- The Virtuoso Pianist in 60 Exercises: Complete",
      fullLink: "https://www.amazon.com/dp/0739009400?C94CE562-AB53-3E47-BCD4-9CB049C7108B_pure_cup_C6FA3ED5_6D17_47D1_B6E2_F4B02CC905E0_=&linkCode=ll1&tag=mwesterham-20&linkId=314225f0069279597135b777590477aa&language=en_US&ref_=as_li_ss_tl",
    },
  ];

  const SectionAccordian = (props: any) => {
    const listOfPanelProps: { title: string, items: AmazonProductCardProps[] }[] = props.items;
    const [isLarge] = useState(window.innerWidth > 1024);

    return (
      <Accordion alwaysOpen flush={!isLarge}>
        {listOfPanelProps.map((panelProps) => (
          <Accordion.Panel isOpen={true}>
            <Accordion.Title>
              {panelProps.title}
            </Accordion.Title>
            <Accordion.Content>
              <div className="grid justify-center lg:grid-cols-2 gap-4">
                {panelProps.items.map((product: AmazonProductCardProps) => (
                  <AmazonProductCard name={product.name} fullLink={product.fullLink} />
                ))}
              </div>
            </Accordion.Content>
          </Accordion.Panel>
        ))}
      </Accordion>
    )
  }

  return (
    <>
      <div className="section-padding" />
      <Title id="violin" text="Violin" />

      <SectionAccordian items={[
        {
          title: "General",
          items: violinProducts
        },
        {
          title: "Beginner Violin Books",
          items: beginnerViolin
        },
        {
          title: "Intermediate Violin Books",
          items: intermediateViolin
        }
      ]}/>
      
      <div className="section-padding" />
      <Title id="piano" text="Piano" />

      <SectionAccordian items={[
        {
          title: "General",
          items: pianoProducts
        },
        {
          title: "Beginner Piano Books",
          items: beginnerPiano
        },
        {
          title: "Intermediate Piano Books",
          items: intermediatePiano
        }
      ]}/>

      <div className="section-padding" />
    </>
  );
};

export default Resources;
