import React, { useEffect } from "react";
import { createRoot } from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ErrorPage, Navbar, Footer } from "./components";
import { Faq, Introduction, Calendar, ContactMe, Resources } from "./routes";

import "./index.css";



const PageContainer = (props: { Page: () => React.JSX.Element }) => {
  useEffect(() => {
    const scrollToHashElement = () => {
      const { hash } = window.location;
      const elementToScroll = document.getElementById(hash?.replace("#", ""));
      if (!elementToScroll) return;
      const offset = 0;
      window.scrollTo({
        top: elementToScroll.offsetTop - offset,
        behavior: "smooth",
      });
    };

    const timer = setTimeout(() => {
      scrollToHashElement();
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <div className="flex flex-col h-screen">
        <div className="bg-color-footer">
          <Navbar />
        </div>
        <div className="flex flex-row justify-center items-center mb-auto px-12 lg:px-36">
          <div className="grow max-w-4xl">
            <props.Page />
          </div>
        </div>
        <div className="bg-color-footer">
          <Footer />
        </div>
      </div>
    </>
  );
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <PageContainer Page={Introduction} />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/faq",
    element: <PageContainer Page={Faq} />,
    errorElement: <ErrorPage />
  },
  {
    path: "/calendar",
    element: <PageContainer Page={Calendar} />,
    errorElement: <ErrorPage />
  },
  {
    path: "/contact_me",
    element: <PageContainer Page={ContactMe} />,
    errorElement: <ErrorPage />
  },
  {
    path: "/resources",
    element: <PageContainer Page={Resources} />,
    errorElement: <ErrorPage />
  },
]);

const container: HTMLElement = document.getElementById("root")!;
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
