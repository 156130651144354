import React from 'react';
import './faq.css';

const AskedQuestion = (props: any)  => {
  return (
    <div className="pb-8">
      <div className="text-center lg:text-left text-4xl">
        <div>{props.question}</div>
        <p className="title-line-xl w-full mx-0 mt-0"></p>
      </div>
      <div className="">
        <div>{props.answer}</div>
      </div>
    </div>);
}

const Faq = () => {
  const questionAndAnswers = [
    {
      "question": "What do I need to bring to a lesson?",
      "answer": "For violin lessons, bring your violin, bow, music books, and a blank notebook. For piano lessons you only need bring a blank notebook and music books. Be curious, willing to learn, and ready to ask questions. Let's make the most of your lesson!"
    },
    // {
    //   "question": "Can I get a discount for back-to-back lessons?",
    //   "answer": "I'm unable to offer a discount for back-to-back lessons. My rates reflect the value of personalized instruction and my experience. However, I'm committed to providing the best musical education for you or your child."
    // },
    {
      "question": "Can you come to my home to hold lessons?",
      "answer": "No, I host lessons at my home studio and am best prepared to teach in this environment."
    },
    {
      "question": "What happens if I cannot make it to the lesson?",
      "answer": "I kindly request that you inform me at least 24 hours in advance if you can't attend a lesson so that we may discuss rescheduling. Open communication ensures the best experience for all and allows for me to best prepare and plan for each students' learning experience."
    }
  ];
  return (
    <>
    <div className="section-padding" />
      <div className="grid lg:grid-cols-2 gap-12">
        {questionAndAnswers.map(
          (qas) => (<AskedQuestion question={qas.question} answer={qas.answer}/>)
        )}
      </div>
    </>
  )
}

export default Faq