import React, { useState } from "react";
import { Spinner } from "flowbite-react";

import "./calendar.css";

const Calendar = () => {
  const [mobileSpinnerOn, setMobileSpinnerOn] = useState(true);
  const [spinnerOn, setSpinnerOn] = useState(true);

  return (
    <>
      <div className="section-padding" />
      <div className="h-screen-75">
        <div className="h-full lg:hidden">
          <div className="flex justify-center">
            <Spinner size="xl" hidden={!mobileSpinnerOn} />
          </div>
          <iframe
            title="Mobile Calendar"
            src="https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23ffffff&ctz=America%2FPhoenix&mode=AGENDA&showNav=1&showPrint=0&showTabs=0&showCalendars=0&showTz=1&showDate=0&showTitle=0&src=OTJnNzVuM3F1b3QxbzVyNG5ubjVvbWM5dHNAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&color=%23F6BF26"
            onLoad={() => setMobileSpinnerOn(false)}
            width="100%"
            height="100%"
          />
        </div>

        <div className="hidden lg:inline">
          <div className="hidden lg:flex justify-center">
            <Spinner size="xl" hidden={!spinnerOn} />
          </div>
          <iframe
            title="Calendar"
            src="https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23ffffff&ctz=America%2FPhoenix&mode=WEEK&showNav=1&showPrint=0&showTabs=1&showCalendars=0&showTz=1&showDate=1&showTitle=0&src=OTJnNzVuM3F1b3QxbzVyNG5ubjVvbWM5dHNAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&color=%23F6BF26"
            onLoad={() => setSpinnerOn(false)}
            width="100%"
            height="100%"
          />
        </div>

      </div>
      <div className="section-padding" />
    </>
  );
};

export default Calendar;
