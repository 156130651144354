import React from "react";
import { Tooltip } from 'flowbite-react';

import "./section_title.css";

const Title = (props: any) => {
  const basename = window.location.href.split('#')[0];
  const copyToClipboard = () => {
    const link = basename + "#" + props.id;
    navigator.clipboard.writeText(link);
  }

  return (
    <>
      <div id={props.id} className="flex flex-col justify-center items-center py-1 lg:p-12 text-3xl sm:text-4xl lg:text-4xl xl:text-6xl">
        <Tooltip content="Link copied!" trigger="click">
          <button onClick={copyToClipboard} className="lg:after:title-line-lg lg:before:title-line-lg xl:after:title-line-xl xl:before:title-line-xl">{props.text}</button>
        </Tooltip>
        <p className="title-line-xl lg:hidden"></p>
      </div>
    </>
  )
}

export default Title;