import React from "react";
import { Card } from "flowbite-react";
import Image from "../image/Image";

import "./amazon_product_card.css";

export interface AmazonProductCardProps {
  name: string;
  fullLink: string;
}

const AmazonProductCard = (props: AmazonProductCardProps) => {
  const splitLink: string[] = props.fullLink.split('/');
  const asin = splitLink[splitLink.length - 1].split("?")[0];
  const imgSrc = `//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=${asin}&Format=_SL500_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=mwesterham-20&language=en_US`;

  return (
    <>
      <a href={props.fullLink} target="_blank" rel="noreferrer">
        <Card className="hover:bg-gray-100">
          <div className="flex flex-col lg:flex-row justify-center items-center lg:h-60">
            <Image img={imgSrc} className="h-40 lg:h-60 lg:max-w-none lg:w-fit xl:h-40" />
            <h5 className="sm:text-2xl lg:text-xl font-bold text-center p-4">
              <p>{props.name}</p>
            </h5>
          </div>
        </Card>
      </a>
    </>
  );
};

export default AmazonProductCard;
