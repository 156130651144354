import React from 'react';
import './footer.css';

const ContactMe = () => {
  const links = [
    { text: "jwesterham2@gmail.com", link: "/contact_me", Icon: () => {
      return (
      <>
        <svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 8v10a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1V8M1 8a1 1 0 0 1 .4-.8l8-5.75a1 1 0 0 1 1.2 0l8 5.75a1 1 0 0 1 .4.8M1 8l8.4 6.05a1 1 0 0 0 1.2 0L19 8"/>
        </svg>
      </>
      )
    }},
    { text: "(651)-666-0840", link: "tel:16516660840", Icon: () => {
      return (
      <>
        <svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m16.344 12.168-1.4-1.4a1.98 1.98 0 0 0-2.8 0l-.7.7a1.98 1.98 0 0 1-2.8 0l-2.1-2.1a1.98 1.98 0 0 1 0-2.8l.7-.7a1.981 1.981 0 0 0 0-2.8l-1.4-1.4a1.828 1.828 0 0 0-2.8 0C-.638 5.323 1.1 9.542 4.78 13.22c3.68 3.678 7.9 5.418 11.564 1.752a1.828 1.828 0 0 0 0-2.804Z"/>
        </svg>
      </>
      )
    }}
  ];
  return (
    <>
      {links.map((contact) => (
        <p className="font-medium my-4 mx-4">
          <a
            className="flex rounded-3xl py-2 px-2 hover:underline"
            href={contact.link}
          >
            <p className="pr-2">
              <contact.Icon />
            </p>
            {contact.text}
          </a>
        </p>
      ))}
    </>
  );
};

const Footer = () => {
  return (
    <div className="flex flex-wrap items-center justify-center py-5">
      <ContactMe/>
    </div>
  )
}

export default Footer