import React, { useState } from "react";
import { Title } from "../../components";
import { useForm } from 'react-hook-form';
import {
  Button,
  Checkbox,
  Label,
  TextInput,
  Textarea,
  CustomFlowbiteTheme,
  Spinner,
  Alert,
} from "flowbite-react";
import axios from "axios";
import { delay } from "../../helpers/global_helpers";

import "./contact_me.css";

interface SendEmailProps {
  anonymous: boolean;
  firstName?: string;
  lastName?: string;
  email?: string;
  message: string;
}

// Move later
const emailUrl = "https://9qqvog5lp2.execute-api.us-east-1.amazonaws.com/prod/";

const sendEmail = async (formData: SendEmailProps) => {
  axios.post(emailUrl, formData)
    .then((value) => {
      console.log(value)
    })
    .catch((err) => {
      console.log(err);
    })
};

const removeRelativeTheme: CustomFlowbiteTheme["textInput"] = {
  field: {
    base: "w-full", // originally "relative w-full"
    input: {
      sizes: {
        custom: "sm:text-md p-2.5",
      },
    },
  },
};

const ContactMe = () => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [isAnonymous, setAnonymous] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [isSent, setIsSent] = useState(false);

  const handleAnonymousChange = (e: any) => {
    setAnonymous(e.target.checked);
  };
  
  const onSubmit = async (data: any) => {
    setIsSending(true);
    await sendEmail({
      anonymous: data.anonymous,
      firstName: data.firstname,
      lastName: data.lastname,
      email: data.email,
      message: data.message,
    });
    setIsSent(true);
    await delay(1500);
    window.location.reload();
  }

  return (
    <>
      <div hidden={!isSending} >
        <div className="fixed top-0 left-0 bottom-0 right-0 h-full w-full grid place-items-center bg-opacity-80 bg-slate-600 z-50">
          <div hidden={!isSent}>
            <Alert color="success">
              <span>
                <p>
                  <span className="font-medium">
                    Sent!
                  </span>
                </p>
              </span>
            </Alert>
          </div>
          <Spinner size="xl" hidden={isSent}/>
        </div>
      </div>

      <div className="section-padding" />
      <Title text="Ask a Question" id="contact_me" />

      <form id="questionform" className="flex flex-col gap-4 overflow-auto p-1" onSubmit={handleSubmit(onSubmit)}>
        <div className="flex items-start gap-2">
          <Checkbox
            id="anonymous"
            size={100}
            {...register("anonymous", { onChange: handleAnonymousChange })}
          />
          <Label htmlFor="anonymous" value="Send Anonymously" />
        </div>

        <div className="lg:grid lg:grid-cols-2 lg:gap-4">
          <div className="mb-2">
            <div className="mb-2 block">
              <Label htmlFor="firstname" value="First Name" />
              {!isAnonymous ? <span className="text-red-600"> *</span> : null}
            </div>
            <TextInput
              theme={removeRelativeTheme}
              id="firstname"
              placeholder="John"
              sizing="custom"
              type="name"
              disabled={isAnonymous}
              {...register("firstname", { required: !isAnonymous })}
            />
            {errors.firstname && !isAnonymous && <p className="text-red-600 mt-1 text-sm">This field is required</p>}
          </div>

          <div>
            <div className="mb-2 block">
              <Label htmlFor="lastname" value="Last Name" />
              {!isAnonymous ? <span className="text-red-600"> *</span> : null}
            </div>
            <TextInput
              theme={removeRelativeTheme}
              id="lastname"
              placeholder="Smith"
              sizing="custom"
              type="name"
              disabled={isAnonymous}
              {...register("lastname", { required: !isAnonymous })}
            />
            {errors.lastname && !isAnonymous && <p className="text-red-600 mt-1 text-sm">This field is required</p>}
          </div>
        </div>

        <div>
          <div className="mb-2 block">
            <Label htmlFor="email1" value="Email" />
            {!isAnonymous ? <span className="text-red-600"> *</span> : null}
          </div>
          <TextInput
            theme={removeRelativeTheme}
            id="email"
            placeholder="jsmith@gmail.com"
            sizing="custom"
            type="email"
            disabled={isAnonymous}
            {...register("email", { required: !isAnonymous })} 
          />
          {errors.email && !isAnonymous && <p className="text-red-600 mt-1 text-sm">This field is required</p>}
        </div>

        <div>
          <div className="mb-2 block">
            <Label htmlFor="comment" value="Message" />
            <span className="text-red-600"> *</span>
          </div>
          <Textarea
            id="message"
            placeholder="comment or question..."
            rows={4}
            {...register("message", { required: true })}
          />
          {errors.message && <p className="text-red-600 mt-1 text-sm">This field is required</p>}
        </div>
        <Button type="submit">Submit</Button>

        
      </form>
      <div className="section-padding" />
    </>
  );
};

export default ContactMe;
