import React, { useState } from 'react';
import './image.css';
import { Spinner } from 'flowbite-react';

const Image = (props: any) => {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <div className="flex flex-col justify-center items-center">
      <div className={props.className} hidden={!isLoading}>
        <Spinner size="xl" />
      </div>
      <img
        className="objects-none"
        src={props.img}
        alt={props.alt}
        onLoad={() => setIsLoading(false)}
        hidden={isLoading}
      />
    </div>
  )
}

export default Image